const basePath = 'https://api.kimbocare.training';
const kimboCampaignFees = 0.12;
const deeplApiKey = 'c33fc85c-93ff-4d12-a52c-130787e20571:fx';
export const environment = {
  production: true,
  serviceWorker: true,
  vapid_key: "BBQnjvOWTyNCzDDhL_CDMDs7le9nYmNxt1X2QCzSczVBo0tUl3reaoF--xr5q0lw7mjbt_qWMP0V1oVZrF4mrto",
  basePath,
  name: 'training',
  deeplApiKey,
  appVersion: require('../../package.json').version,
  badge: true,
  sentry: {
    tracingOrigins: ['https://app.kimbocare.training'],
  },
  kimboCampaignFees,
  recaptcha: {
    siteKey: '6LcRPTYmAAAAAArMl6NN16N_kXQaVOT7EGgczl5d',
  },
  ssk: 'we-sv-rtw--eüd-§$-fd',
  ssv: 'öer:gerv:vjdf+*dfe#e-ee,'
};
